/* 
NOTE: How to use this file: 
  - If you are looking for specific styles, search(ctrl/cmd + f) and type the name of the component/page that the style is in
    (e.g. 'HomeButton Component', 'Register Page'). 
  - If you can't find the style there search for 'Base CSS' or 'Form Base CSS', these sections hold styles that are used 
    multiple places. 
  - There is also a section at the bottom that holds mobile responsive styles search for 'Mobile Responsive' if you are looking 
    for these styles.
*/

/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600;800&family=Open+Sans:wght@400;700&display=swap"); */
@font-face {
  font-family: "Montseratt";
  src: url("./Montserrat/Montserrat-VariableFont_wght.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans";
  src: url(./Open_Sans/OpenSans-VariableFont_wdth_wght.ttf) format("truetype");
}

body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

footer {
  width: 100%;
}

.container {
  width: 100%;
  height: 400px;
}

:root {
  --color-white: #ffffff;
  --color-lighter-gray: #f2f2f2;
  --color-black: #1a1a1a;
  --color-dark-blue: #002f70;
  --color-slate: #415464;
  --color-lighter-slate: #8d98a2;
  --color-lightest-slate: #d9dde0;
  --color-burned-orange: rgb(216, 82, 4);
  --color-just-blue: #2a55a6;
  --color-just-blue-tint-2: #5577b8;
  --color-forescout-blue: #009dea;
  --color-forescout-blue-tint-4: #99d8f7;

  --height-small: 12rem;
  --height-medium: 20rem;
  --height-medium-large: 25rem;
  --height-large: 30rem;
  --height-extra-large: 40rem;
  --height-extra-extra-large: 50rem;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  background: #f2f2f2;
  color: var(--color-black);
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  margin-bottom: 0.25em;
  font-family: "Montserrat", sans-serif;
  font-size: 3rem;
  font-weight: 600;
  margin-left: auto;
  margin-right: auto;
}

h2 {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 1.625rem;
  font-weight: 600;
}

a {
  color: var(--color-dark-blue);
}

.tool_list {
  list-style-type: square;
}

/* Background-Image */
.background-image {
  background: linear-gradient(
      0deg,
      rgba(0, 47, 112, 0.9),
      rgba(0, 47, 112, 0.9)
    ),
    url(https://www.forescout.com/wp-content/uploads/2021/07/test-drive.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  padding: 1rem;
}

.eindhoven-background-image {
  background-image: url(/public/pictures/Eindhoven_Gradient.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

/* Vulnerability Table */
.vuln_table {
  display: grid;
  grid-template-columns: 10rem 10rem 18rem 8rem 18rem;
  max-height: 360px;
  overflow: scroll;
}

.date_found {
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
}

.quick_filters > div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.5rem;
}

.quick_filters {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

@media (min-width: 760px) {
  .quick_filters {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1280px) {
  .quick_filters {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  }
}

.quick_filters > button {
  padding: 0.5rem;
  margin: 0.25rem;
  border: none;
  border-radius: 0.25rem;
  border-color: var(--color-lightest-slate);
  border-width: 0.1rem;
  border-style: solid;
  color: var(--color-slate);
  font-weight: 600;
  background-color: white;
}

.quick_filters > button:hover {
  cursor: pointer;
  background-color: rgb(231, 228, 228);
}

.vuln_table_head,
.empty_table_head {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  position: sticky;
  top: 0;
  background-color: white;
}

.vuln_table_head > p {
  padding-right: 0.5rem;
  font-size: 1rem;
  color: #009dea;
  font-weight: 600;
}

.vuln_table_head:hover {
  cursor: pointer;
  background-color: rgb(231, 228, 228);
}

.vuln_table_header:not(:last-child) {
  border-right: 0.125rem solid #009dea;
}

.vuln_table_row {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: 10rem 10rem 18rem 8rem 18rem;
}

@media (min-width: 1280px) {
  .vuln_table {
    grid-template-columns: 15% 20% 25% 20% 20%;
  }

  .vuln_table_row {
    grid-template-columns: 15% 20% 25% 20% 20%;
  }
}

.vuln_table_row:nth-child(4n-2),
.vuln_table_expanded_row:nth-child(4n-1) {
  background-color: rgb(230, 230, 230);
}

.vuln_table_row:hover {
  background-color: rgb(204, 204, 204);
  cursor: pointer;
}

.vuln_table_body {
  font-size: 0.75rem;
  padding: 0.5rem;
}

.vuln_table_field {
  font-size: 0.75rem;
  margin: 2rem;
  padding-bottom: 1rem;
}

.vuln_table_expanded_row {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.tool_list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  grid-template-rows: repeat(4, 1fr);
}

@media (min-width: 760px) {
  .tool_list {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}

.tool {
  background-color: var(--color-lightest-slate);
  width: 100%;
  color: white;
  border-radius: 0.5rem;
  font-size: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
}

.tool a {
  color: var(--color-forescout-blue);
  text-decoration: none;
  text-align: center;
  padding-bottom: 1rem;
}

.tool a:hover {
  cursor: pointer;
  color: var(--color-forescout-blue-tint-4);
}

.tool img {
  max-width: 80px;
}

.disclosure_policy {
  font-size: 0.75rem;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.disclosure_policy img {
  text-align: center;
  max-width: 200px;
  margin: 1rem 0;
  opacity: 0.9;
}

@media (min-width: 760px) {
  .disclosure_policy img {
    max-width: 300px;
  }
}

.disclosure_policy p {
  padding: 1rem 0;
  text-align: center;
  font-size: 1.5rem;
}

.ordered_list {
  list-style-type: decimal;
  list-style-position: inside;
}

/* Base CSS */
.box {
  background-color: white;
  padding: 2rem;
  margin: 1rem;
  border-radius: 0.5rem;
  text-align: center;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.copilot-box {
  padding: 1rem;
  margin: 0 1rem;
}

.video-container {
  background: white;
  margin-top: 2rem;
  padding: 1rem;
  height: fit-content;
}

.video-container > h3 {
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--color-forescout-blue);
}

.video {
  width: 100%;
}

@media (min-width: 768px) {
  .video-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .video {
    position: unset;
    left: unset;
  }
}

@media (min-width: 1280px) {
  .video-container {
    display: unset;
    flex-direction: unset;
    justify-content: unset;
    align-items: unset;
    margin-top: unset;
    width: 40%;
  }

  .copilot-box {
    padding: 2rem;
    display: flex;
    justify-content: space-between;
  }
}

.grey-box {
  background-color: white;
  color: var(--color-slate);
  padding: 1rem;
}

@media (min-width: 1280px) {
  .grey-box {
    margin: 0 2rem;
    width: 60%;
  }
}

.grey-box > p {
  padding: 1rem;
  line-height: 1.75rem;
  font-size: 1.25rem;
}

.para_title {
  font-size: 1.75rem;
  padding: 0.5rem 0;
  font-weight: 600;
  color: var(--color-forescout-blue);
}

.copilot-link {
  text-decoration: none;
  color: var(--color-forescout-blue);
}

.icon-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.copilot-icon {
  width: 3rem;
  height: 3rem;
  margin: 1rem;
}

.plus {
  font-size: 2rem;
  font-weight: 600;
}

.forescout-icon {
  width: 5rem;
  margin: 1rem;
}

@media (min-width: 1280px) {
  .forescout-icon {
    width: 7rem;
  }
}

.image-container {
  margin: 1rem 0;
}

.instruction-images {
  width: 66%;
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.form-box {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 2rem;
  text-align: center;
}

@media (min-width: 1280px) {
  .form-box {
    max-width: 305px;
    margin-bottom: 10px;
  }
}

.description-text {
  line-height: 1.5rem;
  font-size: 1.25rem;
}

.updated-box {
  background-color: rgba(255, 255, 255, 0.8);
  padding-bottom: 5rem;
  border-radius: 2rem;
  text-align: center;
  position: relative;
}

@media (min-width: 1280px) {
  .updated-box {
    padding: 2rem;
    margin: 1rem;
  }
}

.header {
  color: #009dea;
  font-size: 2.5rem;
  line-height: 2.75rem;
  font-weight: 500;
  padding: 1rem;
}

.vuln-header {
  color: #009dea;
  font-size: 2.5rem;
  line-height: 2.75rem;
  font-weight: 500;
  padding-top: 1rem;
}

.subtitle {
  font-size: 1.5rem;
  line-height: 2rem;
}

.exHeader {
  color: white;
  font-size: 1.875rem;
  line-height: 2rem;
  font-weight: 500;
}

.body {
  font-size: 1.125rem;
  line-height: 1.5rem;
  padding: 0 1rem;
  color: white;
}

.pending {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homeBtn {
  margin-top: 0.5rem;
  font-size: x-large;
}

.faicon {
  margin-right: 0.5rem;
}

.btn {
  margin-left: 1rem;
  text-decoration: none;
  border-radius: 0.5rem;
  border-width: 0.1rem;
  border-style: solid;
  border-color: rgb(229, 231, 235);
  color: rgb(42, 85, 166);
  background-color: white;
  padding: 0.5rem 1.5rem;
  width: fit-content;
  font-size: medium;
  display: flex;
  transition: all 300ms ease-in-out;
  align-items: center;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.btn:hover {
  color: white;
  background-color: rgb(42, 85, 166);
  border-color: rgb(42, 85, 166);
  cursor: pointer;
  transform: scale(1.05);
}

/* Form Base CSS */
.fieldContainer {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  /* padding-bottom: 1rem; */
}

.input {
  border: 2px solid;
  border-color: #0000 #0000 #3f66af;
  border-radius: 0;
  font-size: 1rem;
  line-height: 1.3;
  padding: 0.5em 0.25em;
  font-family: Open Sans, sans-serif;
  box-sizing: border-box;
  width: 100%;
}

.passReq {
  margin: 1rem 0.5rem;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.95);
  top: 150px;
  left: 400px;
  padding: 2rem;
}

.submitBtn {
  text-decoration: none;
  display: flex;
  font-size: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  line-height: 2rem;
  border-radius: 0.5rem;
  border: 0.1rem solid var(--color-forescout-blue);
  color: white;
  background-color: var(--color-forescout-blue);
  padding: 0.5rem 1.5rem;
  margin-top: 1rem;
  width: fit-content;
  transition: all 300ms ease-in-out;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.submitBtn:hover {
  color: rgb(42, 85, 166);
  background-color: white;
  cursor: pointer;
  transform: scale(1.05);
}

.link {
  text-decoration: underline;
  color: rgb(42, 85, 166);
  padding-left: 0.25rem;
}

.link:hover {
  cursor: pointer;
}

.eye {
  color: #2a55a6;
  float: right;
  left: -1.5rem;
  position: relative;
  /* top: -1.75rem; */
}

.info-icon-container {
  color: var(--color-lighter-slate);
  border-color: var(--color-lighter-slate);
  border-width: 0.1rem;
  border-style: solid;
  padding: 0.25rem;
  border-radius: 50%;
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
}

.info-icon-container:hover {
  cursor: pointer;
}

.info-icon {
  width: 1rem;
  height: 1rem;
}

.passwordInput {
  position: relative;
}

.passInput {
  margin-right: -20px;
}

.tableOverflow {
  overflow: scroll;
}

@media (min-width: 768px) {
  .tableOverflow {
    overflow: unset;
  }

  .eye {
    float: unset;
    top: 0;
    margin-right: -50px;
  }
}

@media (min-width: 1280px) {
  .eye {
    left: -3.5rem;
  }
}

.eye:hover {
  cursor: pointer;
  transform: scale(1.15);
}

/* Profile Page */
.blueBox {
  background-color: rgb(42, 85, 166);
  color: white;
}

.boxHeader {
  font-size: 2rem;
  line-height: 2.5rem;
}

@media (min-width: 768px) {
  .threatIcon2 {
    width: 20rem;
    height: 20rem;
    margin: 2rem;
  }
}

.userInfo {
  font-size: 1.5rem;
  line-height: 2rem;
}

.feedservice-section {
  background-image: url("../../public/pictures/FS-2023-Site-Redesign-Hero-Research-v1-1600x700-1.jpg");
  height: 600px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
}

.feedservice-title {
  font-size: 3rem;
  margin: 1rem;
}

.feedservice-desc {
  margin: 1rem;
  max-width: 600px;
  line-height: 1.5rem;
}

.profile-small-section {
  padding: 3rem;
  margin: 1rem;
}

.profile-section {
  margin: 1rem;
  padding: 2rem;
}

.profile-section-small {
  padding: 1rem;
}

.profile-section-small > p {
  padding: 1rem;
  font-weight: 550;
}

.profile-section-small:not(:last-of-type) {
  border-bottom-width: 0.1rem;
  border-bottom-color: var(--color-lightest-slate);
  border-bottom-style: solid;
}

.profile-small-section:not(:last-of-type) {
  border-bottom-width: 0.1rem;
  border-bottom-color: var(--color-lightest-slate);
  border-bottom-style: solid;
}

@media (min-width: 1280px) {
  .profile-area {
    display: flex;
  }

  .profile-section:first-of-type {
    width: 40%;
    border-right-color: var(--color-lightest-slate);
    border-right-width: 0.1rem;
    border-right-style: solid;
  }

  .profile-section:last-of-type {
    width: 60%;
  }
}

.profile-title {
  color: var(--color-forescout-blue);
  font-size: 2rem;
  padding: 1rem;
  text-align: center;
}

.profile-text {
  font-weight: 600;
  padding: 1rem;
  text-align: center;
}

.profile-btn {
  background-color: var(--color-just-blue);
  color: white;
  text-decoration: none;
  padding: 0.75rem;
  border-radius: 0.15rem;
  border-style: none;
  width: 100%;
  display: block;
  text-align: center;
  margin: 1rem 0rem;
}

.profile-btn:hover {
  color: var(--color-just-blue);
  background-color: white;
  border: 0.1rem solid var(--color-just-blue);
  cursor: pointer;
}

.apiContainer {
  margin-bottom: 4rem;
}

.apiKeyContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.apiInput {
  position: relative;
  right: -0.5rem;
  border: 0.15rem solid rgb(216, 82, 4);
  border-radius: 0.35rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  padding: 0.25rem;
  width: 100%;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.apiCopy {
  cursor: pointer;
  position: relative;
  right: 1rem;
  color: rgb(42, 85, 166);
}

.passwordBtn {
  margin-left: auto;
  margin-right: 0.5rem;
}

/* Forgot Page */
.sub_header {
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 1rem;
}

/* UsePolicy page */
.content {
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 1rem 0.5rem;
  text-align: left;
}

.sec_header {
  color: var(--color-dark-blue);
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 0 1rem;
  text-align: left;
}

/* Error Component */
.error {
  font-size: xx-large;
  padding: 2rem;
  display: flex;
  justify-content: center;
}

.icon {
  margin-left: auto;
  transition: all 200ms ease-in-out;
}

.icon:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.codeLine {
  padding-top: 1rem;
}

.highlight {
  color: blue;
}

.quote {
  color: blue;
  text-decoration: underline;
  font-style: italic;
}

.secHighlight {
  color: rgb(245, 97, 227);
}

.field {
  font-size: 1.75rem;
  line-height: 2.25rem;
}

.variables {
  margin: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.subfield {
  font-size: 1.5rem;
  line-height: 2rem;
}

.italic {
  font-style: italic;
  margin-left: 1rem;
  margin-top: 0.25rem;
}

.bold {
  font-weight: 600;
  margin-right: 0.5rem;
  font-style: normal;
}

.response {
  display: flex;
  align-items: center;
  background-color: rgb(243, 244, 246);
  padding: 0.5rem;
  transition: all 100ms ease-in-out;
}

.response:not(:last-child) {
  border-bottom: 0.1rem solid rgb(229, 231, 235);
}

.response:hover {
  background-color: white;
  cursor: pointer;
}

.expand {
  margin-left: auto;
}

.code {
  padding: 0.5rem;
  font-family: monospace;
}

.hidden {
  display: none;
}

.close {
  float: right;
  height: 1.25rem;
  width: 1.25rem;
}

.close:hover {
  cursor: pointer;
  color: rgb(239 68 68);
  transform: scale(1.15);
}

/* Explanations Component */
.explanations {
  margin: 1rem;
}

.explanations_link {
  color: white;
}

.explanation {
  margin-bottom: 2rem;
}

.extitle {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.whyIcon {
  background-color: var(--color-dark-blue);
  border-radius: 50%;
  color: white;
  margin-right: 1rem;
  padding: 1rem;
}

.explanationIcon {
  height: 5rem;
  width: 5rem;
}

.vedereLabsLogo {
  height: 2.5rem;
  margin: 0.5rem;
}

/* Header Component */
.App > header {
  top: 0;
  right: 0;
  left: 0;
}

.navbar {
  background-color: var(--color-forescout-blue);
  display: flex;
  min-height: 80px;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 1280px) {
  .App > div {
    margin-top: unset;
  }
}

.nav-menu-header {
  width: 100%;
  display: flex;
  justify-content: center;
  position: sticky;
  z-index: 9999;
}

.nav-menu-blue-header {
  position: sticky;
}

.nav-menu {
  position: absolute;
  background: hsla(240, 6%, 97%, 0.667);
  backdrop-filter: blur(1em);
  margin: 1rem;
  width: 90%;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  justify-content: space-evenly;
}

.nav-menu-blue {
  position: absolute;
  right: 0;
  max-width: 400px;
  background: rgba(153, 216, 247, 0.9);
  margin: 1rem;
  border-radius: 0.5rem;
  padding: 1rem;
}

.nav-menu-section {
  padding: 0.5rem;
  width: 100%;
  min-height: 272px;
  height: 100%;
}

.nav-menu-section-blue {
  width: 100%;
  height: 100%;
  text-align: center;
}

.nav-menu-section-blue > h2 {
  color: white;
  margin-bottom: 1rem;
  font-weight: 500;
}

.nav-menu-section-small {
  padding: 0.5rem;
  width: 100%;
  height: 100%;
}

.nav-menu-button-blue {
  background-color: var(--color-forescout-blue);
  padding: 0.5rem;
  color: white;
  text-decoration: none;
  border-radius: 0.5rem;
  font-size: 1.5rem;
}

.nav-menu-link:hover {
  cursor: pointer;
  text-decoration: none;
}

.menu-icon {
  color: white;
  width: 3rem;
  height: 2rem;
}

.nav-menu-link {
  color: white;
  text-decoration: none;
  padding: 0.5rem;
  height: 100%;
  display: flex;
  align-items: center;
}

.nav-menu-dashboard {
  min-height: 420px;
}

.nav-menu-text {
  text-align: center;
  font-size: 0.75rem;
  margin-top: 1rem;
}

@media (min-width: 1280px) {
  .nav-menu-link {
    color: black;
    flex-direction: column;
  }

  .nav-menu-link:hover {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 0.1rem;
  }
}

.nav-menu-section:not(:last-child),
.nav-menu-section-small:not(:last-child) {
  border-right-color: var(--color-lighter-slate);
  border-right-style: solid;
  border-right-width: 0.1rem;
}

.nav-menu-section > .nav-menu-link > h2 {
  text-align: center;
  min-height: 78px;
}

.nav-menu-img {
  max-width: 250px;
  margin-top: 1rem;
  height: 160px;
}

.navbar-logo {
  height: 2rem;
  margin-right: 2rem;
}

@media (min-width: 768px) {
  .navbar-logo {
    height: 3rem;
  }
}

.navbar-links {
  color: white;
  font-size: 1.5rem;
  display: flex;
  height: 100%;
  align-items: center;
}

.navbar-links > h3 {
  margin-right: 2.5rem;
  height: 100%;
  align-items: center;
  display: flex;
}

.navbar-link {
  color: white;
  text-decoration: none;
}

.navbarClose {
  display: flex;
}

.navbarContent {
  display: flex;
  flex-direction: column;
}

.headerSection {
  background-image: url("../../public/pictures/FS-Header.png");
  background-position-y: 25%;
  min-height: 15rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.white-logo {
  height: 2rem;
}

@media (min-width: 760px) {
  .white-logo {
    height: 3rem;
  }
}

.pageTitle {
  color: white;
  text-align: center;
  font-size: 2rem;
  margin: unset;
}

.slideContainer {
  background-color: #002f70;
  margin: 2rem;
  text-align: center;
  color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  min-height: 620px;
}

.cards {
  display: grid;
  grid-template-rows: repeat(3, minmax(0, 1fr));
  grid-template-columns: minmax(0, 1fr);
  gap: 2rem;
  margin: 2rem;
}

.card {
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.cardTitle {
  max-width: 100%;
  margin-bottom: 2rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.cardContent {
  line-height: 1.25rem;
  margin-bottom: 2rem;
}

.buttonGroup {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.button {
  background-color: #09a0eb;
  color: white;
  border: none;
  border-radius: 0.25rem;
  transition: transform 100ms ease-in-out;
}

.button:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.circle {
  background-color: white;
  border: none;
  margin: 0.25rem;
  padding: 0.5rem;
  border-radius: 100%;
  transition: transform 100ms ease-in-out;
}

.circle:hover {
  background-color: rgb(204, 204, 204);
  cursor: pointer;
  transform: scale(1.1);
}

.activeCircle {
  background-color: rgb(204, 204, 204);
}

.carouselImg {
  max-width: 100%;
  margin-bottom: 1rem;
}

.carouselTitle {
  font-size: 1.5rem;
  line-height: 1.75rem;
}

.carouselText {
  width: 100%;
}

.slideContainer > div:nth-child(1) {
  min-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
}

.navbarClose {
  display: flex;
}

.open {
  position: fixed;
  height: unset;
  width: 100%;
}

.Grid > div > header > h2 {
  margin: 0 2rem;
}

@media (min-width: 768px) {
  .open {
    width: 18rem;
  }
}

.SecurityFeed > section {
  overflow-x: scroll;
}

.navbarContent {
  display: flex;
  flex-direction: column;
}

.navbarLink {
  color: white;
  text-decoration: none;
  margin: 1rem;
  font-size: 1.5rem;
}

.subNavbarLink {
  color: white;
  text-decoration: none;
  margin: 1rem;
  font-size: 1rem;
}

.navbarLink:hover,
.subNavbarLink:hover {
  cursor: pointer;
}

.logo {
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
}

@media (min-width: 768px) {
  /* .navbarLink {
    white-space: nowrap;
  } */

  .navbarClose {
    justify-content: end;
  }

  .pageTitle {
    font-weight: 700;
    font-size: 3rem;
  }

  .activeItem {
    display: flex;
    justify-content: space-between;
  }

  .activeItem:hover {
    cursor: pointer;
  }

  .carouselImg {
    display: block;
    margin-top: auto;
    margin-bottom: auto;
    width: 33%;
    height: 50%;
    border-radius: 0.25rem;
  }

  .carouselContent {
    width: 66%;
  }

  .button {
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
  }

  .carouselText {
    line-height: 1.75rem;
    font-size: 1.5rem;
    margin: 2rem;
    width: unset;
  }

  .carouselTitle {
    font-size: 3rem;
    line-height: 4rem;
  }

  .circle {
    margin: 0.5rem;
  }

  .slideContainer {
    min-height: 857px;
  }
}

@media (min-width: 1280px) {
  .carouselImg {
    height: unset;
  }

  .cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: minmax(0, 1fr);
  }

  .slideContainer {
    min-height: 520px;
  }
}

.logo {
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
}

.blue-logo {
  background-color: rgba(0, 47, 112, 0.9);
  border-radius: 50%;
  vertical-align: middle;
}

.navbarItem {
  color: white;
  height: 1rem;
  width: 1rem;
  margin: 0.5rem;
  float: right;
}

.margin-top {
  margin: 1rem 0;
}

.w-full {
  width: 100%;
}

.feedBtn {
  background-color: white;
  background-image: linear-gradient(
    to bottom,
    rgba(216, 82, 4, 0.8),
    rgba(216, 82, 4, 1)
  );
  color: white;
  padding: 0.4rem 1rem;
  margin-left: 1rem;
  font-size: 1.5rem;
  line-height: 2rem;
  border-radius: 0.5rem;
  transition: all 200ms ease-in-out;
  text-decoration: none;
}

.feedBtn:hover {
  background-color: white;
  background-image: none;
  color: rgb(216, 82, 4);
}

.dropNav {
  display: flex;
  align-items: flex-end;
}

.dropdown {
  white-space: nowrap;
}

.dropNavItem {
  color: white;
  padding: 1rem;
  line-height: 2rem;
  font-size: 1.5rem;
  text-decoration: none;
}

.activeNav {
  text-decoration: underline;
}

.headerText {
  color: white;
}

.vedereIcon {
  height: 70px;
}

.vedere {
  font-size: 3rem;
}

.logoText {
  font-size: 1.5rem;
  line-height: 2rem;
}

.threatContainer {
  background-color: var(--color-just-blue-tint-2);
  border-radius: 50%;
  padding: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.threatIcon {
  height: 12rem;
  width: 12rem;
}

@media (min-width: 64rem) {
  .threatContainer {
    padding: 2rem;
  }
}

@media (min-width: 64rem) {
  .logoSection {
    /* align-items: center; */
    display: flex;
    flex-direction: column;
  }
}

.navbarMenu {
  color: white;
  height: 1.5rem;
  width: 1.5rem;
  margin: 1rem;
}

.navbarTitle {
  color: white;
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.flex-col {
  flex-direction: column;
}

/* Register Component */
.cookie {
  font-size: x-small;
  padding: 0rem 1rem;
}

.captcha {
  margin-top: 1rem;
  position: relative;
  left: -22px;
}

@media (min-width: 768px) {
  .captcha {
    left: unset;
  }
}

@media (min-width: 1280px) {
  .captcha {
    left: -30px;
  }
}

.boxLabel {
  margin-right: auto;
}

.form {
  text-align: left;
}

/* Registered Component */
.thanksHeader {
  color: rgb(216, 82, 4);
  font-size: 2rem;
  line-height: 2.5rem;
  margin-top: 2rem;
}

.thanksText {
  text-align: center;
  color: rgb(107 114 128);
  margin-top: 3rem;
  font-size: 1.5rem;
  line-height: 2rem;
}

/* SignIn Component */
.reset {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

/* BannerInfo Component */
.BannerInfo {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.BannerInfo h3 {
  margin-bottom: 0.5em;
  font-size: 2.25rem;
  font-weight: 600;
}

.links {
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.research {
  margin-bottom: 1rem;
}

.bannerHeader {
  font-size: 2.5rem;
  line-height: 3rem;
  font-weight: 550;
  color: #002f70;
  text-align: center;
}

/* Tooltip Component */

:root {
  --tooltip-text-color: black;
  --tooltip-background-color: #f2f2f2;
  --tooltip-margin: 50px;
  --tooltip-arrow-size: 6px;
}

/* Footer Component */
.Footer {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  gap: 2em;
  padding: 3em 2em 2em;
  background-color: var(--color-slate);
  color: var(--color-white);
  text-align: left;
  text-transform: uppercase;
  background-image: url("../../public/icons/vedere-v-slate.svg");
  background-repeat: no-repeat;
  background-origin: border-box;
  background-position: 120% 35%;
  background-size: 60%;
  margin-top: -10px;
}

.Footer a {
  color: var(--color-white);
}

.Footer-forescout,
.w-full,
.Footer-social {
  width: 100%;
}

.Footer-forescout {
  display: flex;
  justify-content: space-between;
}

.Footer-social {
  text-align: end;
  margin-top: 2rem;
}

.Footer-forescout a img {
  max-height: 2.5rem;
}

.Footer-social a {
  height: 2.5em;
  width: 2.5em;
  margin-right: 1em;
  padding: 0.6em 0;
  display: inline-block;
  background-color: var(--color-white);
  border-radius: 50%;
  color: var(--color-dark-blue);
  text-align: center;
}

.Footer-legal,
.Footer-links {
  width: 100%;
}

.Footer-legal p {
  margin-bottom: 1em;
}

.footer-text {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 0.75em;
  line-height: 1.25em;
}

.Footer-links p {
  text-align: end;
}

.Footer-links ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 5%;
  margin-bottom: 1em;
}

/* Threat Brief Component */
.Row {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  height: var(--height-large);
}

.Grid > .LineWrapper,
.Grid > .BarWrapper {
  height: var(--height-extra-large);
}

.recharts-tooltip-wrapper {
  z-index: 100;
}

.DonutWrapper
  > section
  > .rechart-responsive-container
  > .recharts-wrapper
  > .recharts-legend-wrapper {
  overflow-y: auto !important;
  height: 100% !important;
  top: 0 !important;
}

.Grid > .Table,
.Grid > .CVETable {
  overflow: auto;
}

.bold {
  font-weight: 700;
}

@media (min-width: 768px) {
  .Grid > .CVETable,
  .Grid > .Table {
    overflow-x: unset;
  }
}

.Grid > * > header {
  flex: 0 0 2em;
  display: flex;
  margin-bottom: 1.5em;
  flex-direction: column;
  justify-content: center;
}

.Grid > * > section {
  flex: 1 0 auto;
}

/* Info Component */
.Info {
  position: absolute;
  top: 0.75em;
  right: 0.75em;
  width: 2em;
  height: 2em;
  color: var(--color-lighter-slate);
  font-size: 1rem;
}

.Info .icon {
  padding: 0.25em;
  border: 0.25em solid var(--color-lighter-slate);
  border-radius: 50%;
  font-weight: 700;
  line-height: 1em;
}

.Info .tooltip {
  position: absolute;
  top: 1.5em;
  right: 1.5em;
  padding: 10px;
  width: 250px;
  background-color: var(--color-white);
  border: 1px solid rgb(204, 204, 204);
  color: var(--color-black);
  font-size: 1rem;
  text-align: left;
  z-index: 1000;
}

.Info p {
  line-height: 1.25em;
}

/* Table Component */
.Table table,
.CVETable table {
  font-size: 0.9rem;
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
  text-align: left;
}

.Table tr,
.CVETable tr {
  padding: 0.5em 1em;
  border: 1px solid var(--color-lightest-slate);
}

.Table td,
.CVETable td {
  padding: 0.5em;
  vertical-align: middle;
}

.Table thead td,
.CVETable thead td {
  position: relative;
  background-color: var(--color-white);
  color: var(--color-just-blue-tint-2);
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 3px solid var(--color-lightest-slate);
}

.Table thead td:not(:last-child):after,
.CVETable thead td:not(:last-child):after {
  position: absolute;
  top: 0.5em;
  right: 0;
  content: "";
  height: calc(100% - 1em);
  border-right: 1px solid var(--color-just-blue-tint-2);
}

/* GreenCheck Component */
.greenCheck {
  color: white;
  background-color: rgb(34 197 94);
  border-radius: 50%;
  width: fit-content;
  padding: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* GreyCheck Component */
.greyCheck {
  color: white;
  background-color: rgb(156 163 175);
  border-radius: 50%;
  width: fit-content;
  padding: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* PasswordReq Component */
.passHeader {
  font-size: 1.75rem;
  line-height: 2.25rem;
  color: var(--color-forescout-blue);
  text-align: left;
}

.passTitle {
  font-size: 1.25rem;
  line-height: 1.75rem;
  text-align: left;
}

.reqContainer {
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
  margin-left: 1rem;
}

.req {
  margin-left: 0.25rem;
}

/* Spinner Component */
.spinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

#ResearchFeed {
  height: fit-content;
}

#SecurityFeed {
  height: fit-content;
  overflow-x: scroll;
}

.CustomLegend {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.75rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 64rem) {
  .fieldContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
}

@media (min-width: 768px) {
  .links {
    display: flex;
  }

  .bannerHeader {
    padding: 0;
  }

  .Grid > .Table,
  .Grid > .CVETable {
    overflow-x: unset;
  }

  #ResearchFeed {
    height: 100%;
  }

  #SecurityFeed {
    height: 100%;
    overflow-x: unset;
  }

  .Footer-social {
    margin: 0;
  }
}

.Grid > .Table {
  white-space: wrap;
}

.disclaimer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media (min-width: 1280px) {
  .Grid {
    grid-template-columns: repeat(12, 1fr);
  }

  .Grid > * {
    grid-column-end: span 3;
  }

  .Grid > .MapWrapper,
  .Grid > .CVETable,
  .Grid > .disclaimer {
    grid-column-end: span 12;
  }

  .Grid > .Stat {
    grid-row-start: 1;
    grid-column-end: span 3;
  }

  .Grid > .HorizontalBarWrapper,
  .Grid > .DonutWrapper,
  .Grid > .ColumnWrapper,
  .Grid > .BarWrapper,
  .Grid > .LineWrapper,
  .Grid > .Table,
  .Grid > .BigBarWrapper,
  .Grid > .SecurityFeed,
  .Grid > .AreaWrapper,
  .Grid > .HistogramWrapper,
  .Grid > .TreemapWrapper,
  .Grid > .CriticalVulns,
  .Grid > .ResearchFeed {
    grid-column-end: span 6;
  }

  .Grid > .ColumnWrapper {
    height: var(--height-medium-large);
  }

  .Grid > .HorizontalBarWrapper,
  .Grid > .BarWrapper,
  .Grid > .LargeStat,
  .Grid > .SmallBarWrapper,
  .Grid > .LineWrapper,
  .Grid > .TreemapWrapper,
  .Grid > .AreaWrapper .Grid > .Table:first-child {
    height: var(--height-large);
  }

  .Footer-links {
    text-align: right;
  }

  .Footer-links ul {
    justify-content: flex-end;
  }

  .boxes {
    display: flex;
    justify-content: space-evenly;
  }

  .box {
    margin: 1rem;
    min-width: 560px;
  }

  .explanations {
    margin: 2rem;
    width: 50%;
  }

  .btns {
    display: flex;
    margin-left: auto;
  }

  .logoSection {
    display: flex;
    align-items: center;
  }

  .vedereIcon {
    margin-left: 1rem;
  }

  .threatContainer {
    padding: 2rem;
  }
}
