.BigBarWrapper .recharts-bar-rectangle {
  -webkit-filter: drop-shadow(2px 0px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(2px 0px 2px rgba(0, 0, 0, 0.7));
}

.sectionTitle {
  background-color: unset;
  border: unset;
  width: 100%;
  grid: unset;
  white-space: nowrap;
  padding: 1rem;
  margin: unset;
}

.mapWidget {
  position: relative;
  width: 100%;
  background-color: white;
  margin: 1rem 1rem 0 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
}

.widgetName {
  text-align: center;
  margin-bottom: 1rem;
}

.tooltip-label {
  background-color: #fff;
  color: rgb(26, 26, 26);
  font-weight: 400;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.25rem;
}

.treemapText {
  font-weight: 300;
}

.RectGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  height: 100%;
}

.rectangle {
  flex: 1 0 calc(100%);
  color: #fff;
}

.rect_id {
  text-align: left;
  margin: 0.25rem 0.5rem;
}

.rect_name {
  margin: 0.25rem;
}

.rect_size {
  font-size: 3rem;
  font-weight: 700;
  margin: 0.5rem;
}

@media (min-width: 768px) {
  .rectangle {
    flex: 1 0 calc(33% - 0.5rem);
  }
}

@media (min-width: 1280px) {
  .rectangle {
    flex: 1 0 calc(25% - 0.75rem);
  }

  .Grid > .BigBarWrapper {
    height: var(--height-medium-large);
  }
}
