.DonutWrapper .recharts-legend-item {
  margin-bottom: 0.5em;
}

.legend-value {
  position: relative;
  top: 1.5px;
  overflow-wrap: break-word;
  color: var(--color-black);
  font-size: 1rem;
}

.recharts-text {
  font-size: 1rem;
}

.BarWrapper .recharts-bar-rectangle,
.SmallBarWrapper .recharts-bar-rectangle {
  -webkit-filter: drop-shadow(2px 0px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(2px 0px 2px rgba(0, 0, 0, 0.7));
}

.Grid {
  display: grid;
  grid-template-columns: 100%;
  gap: 1em;
  margin: 1em;
  text-align: center;
}

.Grid > * {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em;
  position: relative;
  background-color: var(--color-white);
  border: 1px solid #d9d9d9;
  border-radius: 3px;
}

.Grid > .HorizontalBarWrapper,
.Grid > .ColumnWrapper,
.Grid > .MapWrapper,
.Grid > .DonutWrapper,
.Grid > .CVETable,
.Grid > .AreaWrapper {
  height: var(--height-large);
}

.Grid > .LineWrapper,
.Grid > .HistogramWrapper,
.Grid > .BarWrapper,
.Grid > .SmallBarWrapper,
.Grid > .SecurityFeed,
.Grid > .ResearchFeed {
  height: var(--height-extra-large);
}

.Grid > * > header {
  flex: 0 0 2em;
  display: flex;
  margin-bottom: 1.5em;
  flex-direction: column;
  justify-content: center;
}

.Grid > * > section {
  flex: 1 0 auto;
}

.ResearchFeed table {
  width: 100%;
  height: 100%;
  border-collapse: separate;
  border-spacing: 0.5em;
}

.ResearchFeed td {
  padding: 0.75em;
  background-color: var(--color-slate);
  border-radius: 1em;
  color: var(--color-white);
  font-size: 0.8rem;
  text-align: left;
  vertical-align: middle;
}

.ResearchFeed td:first-child {
  text-align: center;
  background-color: var(--color-lighter-gray);
  color: var(--color-black);
}

.ResearchFeed td h3 {
  margin-bottom: 0.25em;
  font-size: 1.25rem;
}

.ResearchFeed td p {
  line-height: 1.5em;
}

.ResearchFeed td a {
  color: var(--color-burned-orange);
}

.SecurityFeed table,
.CriticalVulns table {
  width: 100%;
  height: 100%;
  border-collapse: separate;
  border-spacing: 0 0.5em;
}

.SecurityFeed tr,
.CriticalVulns tr {
  background-color: var(--color-lighter-gray);
}

.SecurityFeed td,
.CriticalVulns td {
  padding: 0.75em;
  text-align: center;
  vertical-align: middle;
}

.SecurityFeed td:first-child,
.CriticalVulns td:first-child {
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
}

.SecurityFeed td:last-child,
.CriticalVulns td:last-child {
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
}

.SecurityFeed .score,
.CriticalVulns .score {
  display: inline-block;
  width: 4em;
  height: 4em;
  padding: 1.5em 0;
  background-color: var(--color-dark-blue);
  border-radius: 50%;
  color: var(--color-white);
  text-align: center;
}

.SecurityFeed .title,
.CriticalVulns .title {
  text-align: left;
}

.SecurityFeed .title a,
.CriticalVulns .title a {
  font-size: 0.8rem;
  font-weight: 700;
  list-style: 1.5em;
}

.Stat {
  height: var(--height-small);
  justify-content: space-evenly;
}

.Stat h3 {
  color: var(--color-dark-blue);
  font-size: 3rem;
  font-weight: 600;
}

.LargeStat h3 {
  color: var(--color-dark-blue);
  font-size: 8rem;
  font-weight: 700;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Stat h2,
.LargeStat h2 {
  font-weight: 600;
}
