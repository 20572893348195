:root {
  --tooltip-text-color: rgb(26, 26, 26);
  --tooltip-background-color: white;
  --tooltip-margin: 50px;
  --tooltip-arrow-size: 6px;
}

.Tooltip-Tip::before {
  content: " ";
  left: 50%;
  border: solid transparent;
  position: absolute;
  border-width: var(--tooltip-arrow-size);
}

.Tooltip-Content {
  position: relative;
  text-align: center;
}

.Tooltip-Wrapper {
  position: relative;
}

.Tooltip-Tip {
  position: absolute;
  border-radius: 0.25rem;
  border-color: #d9dde0;
  border-style: solid;
  border-width: 0.1rem;
  left: 100%;
  transform: translateX(-100%);
  padding: 0.25rem;
  color: var(--tooltip-text-color);
  background: #fff;
  font-size: 1rem;
  font-weight: 400;
  font-family: sans-serif;
  line-height: 1;
  width: 300px;
  z-index: 10000;
}

.Tooltip-Tip.top {
  bottom: 100%;
  /* left: 50%; */
}
