:root {
  --color-white: #ffffff;
  --color-lighter-gray: #f2f2f2;
  --color-black: #1a1a1a;
  --color-dark-blue: #002f70;
  --color-slate: #415464;
  --color-lighter-slate: #8d98a2;
  --color-lightest-slate: #d9dde0;
  --color-burned-orange: rgb(216, 82, 4);
  --color-just-blue: #2a55a6;
  --color-just-blue-tint-2: #5577b8;
  --color-forescout-blue: #009dea;
  --color-forescout-blue-tint-4: #99d8f7;

  --height-small: 12rem;
  --height-medium: 20rem;
  --height-medium-large: 25rem;
  --height-large: 30rem;
  --height-extra-large: 40rem;
  --height-extra-extra-large: 50rem;
}

.column {
  display: flex;
  flex-direction: column;
  text-align: start;
  margin: 2rem;
}

.fieldname {
  font-size: 1.75rem;
  line-height: 2.25rem;
  margin: 1rem 0;
}

.desc-section {
  text-align: center;
}

.desc-section > h2 {
  margin-bottom: 1rem;
}

.example {
  background-color: var(--color-lightest-slate);
  margin: 1rem 0;
  overflow-wrap: break-word;
}

.codeHeader {
  background-color: var(--color-lighter-slate);
  padding: 1rem;
  display: flex;
  align-items: center;
  font-size: 1.25rem;
}

.code {
  padding: 1rem;
  font-family: monospace;
  background-color: var(--color-lightest-slate);
}

.codeLine {
  padding-top: 1rem;
}

.highlight {
  color: blue;
}

.quote {
  color: blue;
  text-decoration: underline;
  font-style: italic;
}

.field {
  font-size: 1.75rem;
  line-height: 2.25rem;
}

.variables {
  margin: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.subfield {
  font-size: 1.5rem;
  line-height: 2rem;
}

.italic {
  font-style: italic;
  margin-left: 1rem;
  margin-top: 0.25rem;
}

.bold {
  font-weight: 600;
  margin-right: 0.5rem;
  font-style: normal;
}

.responses {
  border-radius: 0.5rem;
  overflow-wrap: break-word;
  margin: 1rem 0;
  height: 100%;
}

.response {
  display: flex;
  align-items: center;
  background-color: var(--color-lighter-slate);
  padding: 1rem;
}

.response:not(:last-of-type) {
  border-bottom: 0.1rem solid white;
}

.response:hover {
  background-color: var(--color-lightest-slate);
  cursor: pointer;
}

.close {
  float: right;
  height: 1.25rem;
  width: 1.25rem;
}

.close:hover {
  cursor: pointer;
  color: rgb(239 68 68);
  transform: scale(1.15);
}

.redCircle {
  border-radius: 50%;
  background-color: red;
  height: 1rem;
  width: 100%;
  max-width: 1rem;
  margin-right: 0.5rem;
}

.expand {
  margin-left: auto;
}

.hidden {
  display: none;
}

.secHighlight {
  color: rgb(245, 97, 227);
}

.greenCircle {
  border-radius: 50%;
  background-color: green;
  height: 1rem;
  width: 100%;
  max-width: 1rem;
  margin-right: 0.5rem;
}

.documentation {
  padding: 1rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

@media (min-width: 1280px) {
  .documentation {
    width: 175%;
  }
}

.subheader {
  font-size: 1.875rem;
  line-height: 2.25rem;
  margin-bottom: 1rem;
  text-align: center;
  color: var(--color-forescout-blue);
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem;
}

.flex {
  display: flex;
  align-items: center;
}

.hover:hover {
  cursor: pointer;
}

.text {
  margin-left: 0.5rem;
  font-weight: 500;
}

.response > :nth-child(2) {
  max-width: 10rem;
}

@media (min-width: 768px) {
  .response > :nth-child(2) {
    max-width: unset;
  }

  .text {
    margin-left: auto;
    margin-right: auto;
  }
}

.menu {
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 0.1rem solid rgb(229 231 235);
}

.hide {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
}

.hide:hover {
  cursor: pointer;
  color: red;
}

.menuItems {
  margin-right: 1rem;
  margin-top: 0.5rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}

.menuItems:hover {
  cursor: pointer;
  background-color: rgb(243, 244, 246);
}

.active {
  background-color: rgb(229, 231, 235);
}

.active:hover {
  background-color: rgb(229, 231, 235);
}

.tableNote {
  margin-left: 1rem;
  margin-top: 0.25rem;
}

.table {
  margin: 1rem 0;
  width: 100%;
}

.tableHead:not(:last-of-type),
.tableData:not(:last-of-type) {
  border-right: 0.1rem solid white;
}

.tableRow:not(:last-of-type) {
  border-bottom: 0.1rem solid white;
}

.tableHead,
.tableData {
  padding: 1rem;
}

.tableData {
  background-color: var(--color-lightest-slate);
}

.tableHead {
  background-color: var(--color-lighter-slate);
}

.tab {
  padding-left: 1rem;
}

.tab2 {
  padding-left: 2rem;
}

.tab3 {
  padding-left: 3rem;
}

.tab4 {
  padding-left: 4rem;
}

.tab5 {
  padding-left: 5rem;
}
